import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

/*const HrSmall = () => (
  <hr 
  	css={theme => ({
	  boxShadow: '1px 1px 3px #000',
      maxWidth: '6.25rem',
      margin: '1rem auto',
      borderWidth: '.25rem',
      borderColor: 'inherit',
      borderRadius: '.1875rem',
      fontSize: '.875em',
      opacity: .25,
      color: theme.colors.surface3
    })}
  />
)

const HeaderNoImg = ({ children }) => (
	<header 
      css={{
        margin: '5rem 0 1.25rem',
        position: 'relative',
        background: 'no-repeat center center',
        backgroundSize: 'cover',
        marginTop: '3.1875rem',
        marginBottom: '2.1875rem',
        '@media(min-width: 992px)': {
          marginTop: '5.5rem',
		}
      }}
	>
	  <Container>
        <Row>
          <Col lg={{span: 8, offset: 2}} md={{span: 10, offset: 1}}>
		  	{children}     
          </Col>
        </Row>
      </Container>
	</header>
) 
*/
const Heading = props => (
    <div 
      css={theme => ({
        padding: '6.25rem 0',
        hyphens: 'none',
        textShadow: '2px 2px 3px #000',
        textAlign: 'center',
        color: theme.colors.surface3,
        '@media(min-width: 992px)': {
          padding: '9.375rem 0'
        },
        'h1': {
          fontSize: '3.125rem',
          marginTop: 0,
          '@media(min-width: 992px)': {
            fontSize: '5rem'
          },
        },
		'hr': {
          boxShadow: '1px 1px 3px #000',
          maxWidth: '6.25rem',
          margin: '1rem auto',
          borderWidth: '.25rem',
          borderColor: 'inherit',
          borderRadius: '.1875rem',
          fontSize: '.875em',
          opacity: .25,
          color: theme.colors.surface3
	    },
        'h2': {
          fontWeight: 400,
          fontSize: '1.6875rem',
          lineHeight: 1.1,
          display: 'block',
          margin: '.625rem 0 0',
          color: theme.colors.surface3
        }
      })}       
    >
      <h1>{props.main}</h1>
      <hr />
      <h2>{props.sub}</h2>
    </div>
  )

export default function Header() {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          description
          fullTitle
          title
        }
      }
      heroImage: file(relativePath: {eq: "seesaw.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 789, srcSetBreakpoints:[767, 991, 1200]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    } 
  `)
  const backgroundImg = ((data.heroImage.childImageSharp.fluid.srcSet).split(/\n/)).map(x => x.replace(/\s\S+/, "")).map(y => 'url(' + y + ')');
  const backgroundImgWebp = ((data.heroImage.childImageSharp.fluid.srcSetWebp).split(/\n/)).map(x => x.replace(/\s\S+/, "")).map(y => 'url(' + y + ')');
  return (
    <header 
      css={{
        margin: '5rem 0 1.25rem',
        position: 'relative',
        background: 'no-repeat center center',
        backgroundSize: 'cover',
        marginTop: '3.1875rem',
        marginBottom: '2.1875rem',
        '@media(min-width: 992px)': {
          marginTop: '5.5rem',
            backgroundImage: [
            backgroundImg[2],
            backgroundImgWebp[2]
          ]
        },
        '@media(max-width: 991px)': {
          backgroundImage: [
            backgroundImg[1],
            backgroundImgWebp[1]
          ]
        },
        '@media(max-width: 767px)': {
          backgroundImage: [
            backgroundImg[0],
            backgroundImgWebp[0]
          ]
        }
      }}
    >
      <Container>
        <Row>
          <Col lg={{span: 8, offset: 2}} md={{span: 10, offset: 1}}>
            <Heading 
              main={data.site.siteMetadata.fullTitle} 
              sub={data.site.siteMetadata.description}
            />     
          </Col>
        </Row>
      </Container>
    </header>
  )
}