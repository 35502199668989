import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { Card, CardGroup, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const CardButton = (props) => (
  <Button
    variant=""
    css={(theme) => ({
      fontFamily: "cooper hewitt,helvetica neue,helvetica,arial,sans-serif",
      textTransform: "uppercase",
      fontSize: "0.875rem",
      fontWeight: 800,
      letterSpacing: 1,
      padding: "0.625rem",
      backgroundColor: theme.colors.surface1,
      color: theme.colors.pager,
      borderRadius: 0,
      border: "1px solid",
      borderColor: theme.colors.pager,
    })}
  >
    <Link css={(theme) => ({ color: theme.colors.pager })} to={props.link}>
      {props.btnText} &rarr;
    </Link>
  </Button>
);

export const CardCustom = (props) => (
  <Card
    css={(theme) => ({
      background: theme.colors.surface2,
      border: "1px solid",
      borderColor: theme.colors.element4,
      "a&:hover,a&:focus": {
        color: theme.colors.tertiary,
      },
    })}
  >
    {props.children}
  </Card>
);

export const CardFeatured = (props) => (
  <CardCustom>
    <Card.Img variant="top" as={Img} fluid={props.img} />
    <Card.Body
      css={{
        padding: "1rem",
      }}
    >
      <Card.Title
        css={(theme) => ({
          fontSize: "1.25rem",
          fontFamily: "cooper hewitt,helvetica neue,helvetica,arial,sans-serif",
          fontWeight: 800,
          color: theme.colors.pager,
          lineHeight: 1.1,
          marginBottom: "0.5rem",
          marginTop: 0,
        })}
      >
        {props.title}
      </Card.Title>
      <Card.Text>{props.text}</Card.Text>
      <Card.Footer
        css={(theme) => ({
          borderTop: "none",
          background: theme.colors.surface2,
          padding: "0.5rem 0",
        })}
      >
        {props.children}
      </Card.Footer>
    </Card.Body>
  </CardCustom>
);

export const CardsFeatured = ({ children }) => (
  <CardGroup
    css={{
      margin: "25px 0",
    }}
  >
    {children}
  </CardGroup>
);

export const CardMain = ({ children }) => (
  <CardCustom>
    <Card.Body>{children}</Card.Body>
  </CardCustom>
);
