import React from 'react';
import Layout from '../components/layout';
import Header from '../components/header';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function HomeTemplate({ children }) {
  return (
    <Layout>
      <Header />
      <Container fluid>
        <Row>
          <Col lg={{span: 8, offset: 2}} md={{span: 10, offset: 1}}>
            {children}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}