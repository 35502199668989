import React from 'react';
import { graphql } from 'gatsby';
import HomeTemplate from '../templates/home';
import Video from '../components/video';
import { CardMain, CardsFeatured, CardFeatured, CardButton } from '../components/cards';
import 'bootstrap/dist/css/bootstrap.min.css';

// markup
const IndexPage = ({ data }) => {
  return (
    <main>
      <title>Watchung Co-op</title>
      <HomeTemplate>
        <CardMain>
          <p>
            Welcome to Watchung Cooperative Preschool, a nonprofit community
            learning through play, connecting parents, teachers and children
            since 1967. Our hands-on, play-based educational programs provide a
            nurturing environment for children two-and-a-half to five years old.
            Our philosophy emphasizes the social and emotional well-being of our
            students and fosters their love of learning while nurturing each
            child’s individual learning style.
          </p>
          <p>
            Preschool is one of the first decisions you will make that
            influences the educational, social and personal priorities you set
            for your child. School signifies your child’s first big step away
            from home. It becomes their launch pad for learning, developing and
            connecting socially with the world around them. It is a precious
            time. It is a fleeting moment. You want to be a part of it.
          </p>
          <p>
            At the Watchung Co-op, we understand the need to be involved in your
            child’s first preschool experience - and we make it a reality.​
          </p>
        </CardMain>
        <CardsFeatured>
          <CardFeatured
            img={data.chalk.childImageSharp.fluid}
            title="Interested in a Tour?"
            text="Families considering the Co-op for school year 2021-22 are welcome to schedule a visit!"
          >
            <CardButton link="/visit" btnText="Learn More" />
          </CardFeatured>
          <CardFeatured
            img={data.icepops.childImageSharp.fluid}
            title="Ready to Enroll?"
            text="We look forward to you joining the Co-op family. Registration forms are available online."
          >
            <CardButton link="/apply" btnText="Apply Now" />
          </CardFeatured>
        </CardsFeatured>
        <Video
          videoSrcURL="https://drive.google.com/file/d/1uHEXfrAR873r88n3nxmrUkYnytgkAMlR/preview"
          videoTitle="Virtual Tour of Watchung Co-op"
        />
      </HomeTemplate>
    </main>
  );
}

export const data = graphql`
    query {
      icepops: file(relativePath: { eq: "icepops.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chalk: file(relativePath: { eq: "chalk.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `

export default IndexPage
